

















































































































































































































































































































































































































.how_lci_help_you {
  .letter {
    font-size: 40px;
  }
  .our_title {
    display: flex;
    gap: 8px;

    .img {
      width: 20px;
      height: 20px;
    }
    .title {
      color: #515151;
      margin: 0;
      text-transform: inherit;
      // font-size: ;
    }
  }
  .description_p {
    .desc {
      color: #515151;
      margin: 0;
    }
  }
  .section_three {
    .wrapper_content {
      border-top: 7px solid #00563f;
      padding-top: 10px;
      margin-bottom: 30px;
    }
    .titles {
      color: #515151;
      margin-bottom: 10px;
    }
    .desc {
      color: #777;
      font-size: 13px;
    }
    .lists {
      padding: 0 0 0 15px;
      li {
        color: #777;
        font-size: 13px;
      }
    }
  }
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
// Fixed Bug In Background Color In Medium And Small Screens
::v-deep .sequence {
  &.main-label {
    color: #fff;
    background-color: #39c;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .confluence {
  &.main-label {
    color: #fff;
    background-color: #c36;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .technical_reasoning {
  &.main-label {
    color: #fff;
    background-color: #f93;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .precision {
  &.main-label {
    background-color: #3c3;
    color: #fff;
    &::before {
      opacity: 0;
    }
  }
}
